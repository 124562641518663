import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { Asyncaxioscall } from '../helper/Asyncaxioscall';
import SEO from '../components/seo';
import BreadcrumbCustom from '../components/breadcrumbCustom';
import NotFound from './notfound';
import Content from '../components/content';
import Quoteform from '../components/quoteform';
import Loader from '../components/loader';
import Portfolio from '../components/portflio';
import Review from '../components/reviews';
import Contact from '../components/contact';
import Service from '../components/service';
const Page = ({ match }) => {
  const {
    params: { slug },
  } = match;
  const [show, setShow] = React.useState(false);
  const [pageData, setResponse] = React.useState('');
  const [title, setTitle] = React.useState('');
  React.useEffect(() => {
    setShow(true);
    Asyncaxioscall('wp-json/wp/v2/pages/?slug=' + slug + '&_embed').then((result) => {
      let title = '';
      let pageData = result.data[0] ? result.data[0] : '';
      if (pageData && pageData.yoast_meta[4].property === "og:title") {
        title = pageData.yoast_meta[4].content;
      }
      else if (pageData && pageData.yoast_meta[3].property === "og:title") {
        title = pageData.yoast_meta[3].content;
      }
      setResponse(pageData);
      setTitle(title);
      setShow(false)
    });
  }, [slug]);
  return (
    <div className="page-wrapper">
      {!pageData && <NotFound />}
      {pageData &&
        <div>
          {show && <Loader/>}
          <SEO yoast_head={pageData.yoast_head} title={title} keyword={pageData.acf?pageData.acf.keyword:pageData.title.rendered}/>
          <Header />
          <BreadcrumbCustom {...pageData} />
          {pageData.content && pageData.content.rendered && <Content {...pageData}/>}
          {pageData.acf && pageData.acf.template_name==="QuoteForm" && <Quoteform/>}
          {pageData.acf && pageData.acf.template_name==="Portfolios" && <Portfolio/>}
          {pageData.acf && pageData.acf.template_name==="Reviews" && <Review/>}
          {pageData.acf && pageData.acf.template_name==="Contact" && <Contact/>}
          {pageData.acf && pageData.acf.template_name==="Services" && <Service/>}
          <Footer />
        </div>
      }
    </div>
  );
}
export default Page;