import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import API from '../api';
export default class Widget2 extends Component {
    state = {
        items: [],
    }
    _isMounted=false;
    componentDidMount() {
        this._isMounted=true;
        API.get('wp-json/wp/v2/widget2')
            .then(res => {
                if(this._isMounted){
                const items = res.data;
                this.setState({ items });
                }
            })
    }
    componentWillUnmount() {
        this._isMounted = false;
      }
    render() {
        return (
            <div className="widget">
                <h4 className="widget-title">Services</h4>
                <ul className="footer-ul">
                    {this.state.items.map((item, key) => (
                        <li key={'footer2_' + key}>
                            <Link to={item.slug} >{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}