import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Slider from '../components/slider';
import Process from '../components/process';
import Welcome from '../components/welcome';
import Portfolio from '../components/portflio';
import Review from '../components/reviews';

const Home=()=>{
  return (
    <div className="page-wrapper">
       <Header/>
       <Slider/>
       <Process/>
       <Welcome/>
       <Portfolio limit="4"/>
       <Review limit="4"/>
       <Footer/>
    </div>
    );
}
export default Home;