import React from 'react';
const Star = ({ rating }) => {
    const stars = [1, 2, 3, 4, 5];
    const starHtml = [];
    for (const [index, value] of stars.entries()) {
        if (value <= rating) {
            starHtml.push(<i className="lar la-star mr-1" key={'star_' + index}></i>)
        } else {
            starHtml.push(<i className="las la-star mr-1" key={'star_' + index}></i>)
        }
    }
    return starHtml;
}
export default Star;