import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import MainMenu from './mainmenu';
import API from '../api';
import { LazyLoadImage} from 'react-lazy-load-image-component';

export default class Header extends Component {
  state = {
    favicon: '',
    logo: '',
    contact: ''
  }
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    API.get('wp-json/acf/v2/options')
      .then(res => {
        if (this._isMounted) {
          const favicon = res.data.acf.favicon.url;
          const logo = res.data.acf.logo;
          const contact = res.data.acf.contact;
          this.setState({ favicon, logo, contact });
        }
      })
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <header className="header-wrapper fixed-top">
        <Helmet key="first_call">
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <link rel="icon" href={this.state.favicon} />
        </Helmet>
        <div className="header-top d-lg-flex align-items-center d-none">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-9 col-lg-4 ">
                <Link className="navbar-brand py-3" to="/">
                  <LazyLoadImage
                    alt={this.state.logo.alt}
                    height="35"
                    effect="blur"
                    src={this.state.logo.url} />
                </Link>
              </div>
              <div className="text-right  col-3 col-lg-8 d-flex align-items-center justify-content-end">
                <a href={'tel:' + this.state.contact} className="contact-numb">
                  <i className="las la-phone-volume"></i>
                  <span className="d-none d-lg-inline">{this.state.contact}</span>
                </a>
                <Link to="/get-a-quote" className="primary-btn" >Get A Free Quote</Link>
              </div>
            </div>
          </div>
        </div>
        <Navbar collapseOnSelect expand="lg" className="navbar-classic">
          <div className="container">
            <Link className="navbar-brand py-4 d-lg-none d-flex pl-3" to="/"><img src={this.state.logo.url} height="35" alt={this.state.logo.alt} /></Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <MainMenu />
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    )
  }
}
