import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import API from '../api';
import Widget1 from './widget1';
import Widget2 from './widget2';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { LazyLoadImage} from 'react-lazy-load-image-component';
export default class Footer extends Component {
  _isMounted = false;
  state = {
    address: '',
    email: '',
    contact: '',
    opening: '',
    socials: [],
    payments: [],
    copyright: ''
  }
  componentDidMount() {
    this._isMounted = true;
    API.get('wp-json/acf/v2/options').then(res => {
      if (this._isMounted) {
        const address = res.data.acf.address;
        const email = res.data.acf.email;
        const contact = res.data.acf.contact;
        const opening = res.data.acf.opening_days;
        const socials = res.data.acf.social_links;
        const payments = res.data.acf.payment_partners;
        const copyright = res.data.acf.copyright;
        this.setState({
          address,
          email,
          contact,
          opening,
          socials,
          payments,
          copyright
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <footer>
        <div className="bg-black py-5 footer">
          <div className="container">

            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="quote-box text-center">
                  <h3 className="mb-0">Do you want to connect with us?</h3>
                  <p className="mb-4">Just click the button and fill the form. Our team will respond you within an hour. Have a great day !</p>
                  <Link to="/get-a-quote" className="primary-btn">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="footerline my-5"></div>

            <div className="row">
              <div className="col-lg-3 text-lg-left text-center mb-3">
                <div className="widget">
                  <h2 className="widget-title">Contact 24/7</h2>
                  <div className="feature-block">
                    <figure><i className="las la-map-marker-alt"></i></figure>
                    <div className="content-block">
                      <strong className="d-block">Address</strong>
                      <p className="mb-0" dangerouslySetInnerHTML={{ __html: this.state.address }}></p>
                    </div>
                  </div>

                  <div className="feature-block">
                    <figure><i className="las la-envelope-open-text"></i></figure>
                    <div className="content-block">
                      <strong className="d-block">Email</strong>
                      <p className="mb-0"><a href={'mailto:' + this.state.email}>{this.state.email}</a></p>
                    </div>
                  </div>

                  <div className="feature-block">
                    <figure><i className="las la-phone-volume"></i></figure>
                    <div className="content-block">
                      <strong className="d-block">Contact</strong>
                      <p className="mb-0"><a href={'tel:' + this.state.contact}>{this.state.contact}</a></p>
                    </div>
                  </div>

                  <div className="feature-block">
                    <figure><i className="las la-business-time"></i></figure>
                    <div className="content-block">
                      <strong className="d-block">Opening Days</strong>
                      <p className="mb-0">{this.state.opening}</p>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-lg-3 text-lg-left text-center mb-3">

                <Widget1 />

              </div>
              <div className="col-lg-3 text-lg-left text-center mb-3">

                <Widget2 />
              </div>
              <div className="col-lg-3 text-lg-left text-center mb-3">
                <h4 className="widget-title mb-3">Stay Connected </h4>
                <div className="mb-4">
                  {this.state.socials.map((item, key) => (
                    <a key={'social_' + key} href={item.link} title={item.title} className="social-icon" target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{ __html: item.icon }}></a>
                  ))}
                </div>

                <h4 className="widget-title mb-3">Payment Method</h4>
                <ul className="payment-ul">
                  {this.state.payments.map((item, key) => (
                    <li key={'payment_' + key}>
                       <LazyLoadImage title={item.title} src={item.logo.url} alt={item.logo.alt} className="rounded" effect="blur" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>

          </div>
        </div>
        <div className="bg-black my-transparent py-2">
          <div className="container">
            <div className="text-center"><small>{this.state.copyright}</small></div>

          </div>
        </div>
        <MessengerCustomerChat
          pageId="338356446802985"
          appId="163314552229454"
        />
      </footer>
    )
  }
}
