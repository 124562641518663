import React from 'react';
import { Link } from 'react-router-dom';
const BreadcrumbCustom = (item) => {
    const banner=item.acf&&item.acf.banner ?item.acf.banner.url:'';
    return (
        <div className="ip-banner web-development-banner" style={{ backgroundImage: `url(${banner})` }}>
            <div className="content d-flex align-items-center justify-content-center text-center">
                <div className="container">
                    <div className="breadcrumb-holder d-inline-flex">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">HOME<i className="las la-angle-double-right"></i></Link>
                                </li>
                                {item.title && <li className="breadcrumb-item active" aria-current="page">{item.title.rendered}</li>}
                            </ol>
                        </nav>
                    </div>

                    {item.title && <h1>{item.title.rendered}</h1>}
                   {item.acf && <p>{item.acf.tagline}</p>}
                </div>
            </div>
        </div>
    );
}
export default BreadcrumbCustom;