

import React from 'react';
import { Link } from 'react-router-dom';
import { Asyncaxioscall } from '../helper/Asyncaxioscall';
import { LazyLoadImage} from 'react-lazy-load-image-component';
const Service = ({limit=100}) => {

  const [items, setResponse] = React.useState('');
  React.useEffect(() => {
    let url=limit?'wp-json/wp/v2/service?per_page='+limit+'&_embed':'wp-json/wp/v2/service?_embed';
    Asyncaxioscall(url).then((result) => {
      setResponse(result.data);
    });
  }, [limit]);
  if(!items) return null;
  return (
    <div className="py-5">
    <div className="container">
        <div className="text-center">
            <h2 className="mb-0">Our Services</h2>
            <p className="mb-4">See our list of services. We always try to focus on quality and delivery on time in each services.</p>
        </div>
        <div className="row">
            {items.map((item, key) => (
                <div className="col-lg-6 mb-4" key={key}>
                    <div className="box-with-img">
                        <Link to={'/' + item.type + '/' + item.slug} className="img-container d-block position-relative">
                            {item._embedded && 
                            <LazyLoadImage title={item.title.rendered} src={item._embedded['wp:featuredmedia'][0].source_url} alt={item._embedded['wp:featuredmedia'][0].alt_text} className="img-fluid"  effect="blur" />
                            }
                            <div className="act-overlay"></div>
                            <h3 className="title">
                                <Link to={'/' + item.type + '/' + item.slug}> {item.title.rendered}</Link>
                            </h3>
                        </Link>
                        
                    </div>
                </div>
            ))}
        </div>
    </div>
</div>
  );
}
export default Service;