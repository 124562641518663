import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Loader from '../components/loader';
import LoadTime from '../loadTime';
export default class NotFound extends Component {
  _isMounted = false;
  state = {
   status:true
  }
  componentDidMount() {
    this._isMounted = true;
    setTimeout(() => {
      if(this._isMounted) {
        const status=false;
        this.setState({status});
      }
    }, LoadTime);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    if(this.state.status) return(<Loader/>);
    return (
      <div className="page-wrapper bg-gray">
      <Helmet key="second_helmet">
        <title>Page Not Found</title>
      </Helmet>
      <Header/>
      <div className="banner text-center">
        <div className="container">
          <h1 className="mb-0 text-white">Page Not Found</h1>
          <p className="mb-4 text-white">Oops - It seems the page you are looking for is not here.</p>
          <div>
          <Link to="/" className="primary-btn btn-white font-weight-normal" >Go Home</Link>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    )
  }
}
