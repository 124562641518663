import React from 'react';
import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
const SEO = ({yoast_head, title, keyword}) => {
    return (
        <Helmet key="second_call">
            {title && <title>{ReactHtmlParser(title)}</title>}
            { ReactHtmlParser(yoast_head) }
            {
                keyword && <meta name="keywords" content={keyword}></meta>
            }

        </Helmet>
    );
}
export default SEO;