import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import API from '../api';
export default class Slider extends Component {
  state = {
    items: []
  }
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    API.get('wp-json/acf/v2/options/slider').then(res => {
      if (this._isMounted) {
        const items = res.data.slider;
        this.setState({ items});
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <Carousel className="carousel-fade">
        {this.state.items.map((item, key) => (
          <Carousel.Item key={'slider_' + key}>
            <div className="banner">
              <div className="content d-flex align-items-center justify-content-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-6 text-md-left text-center mb-4">
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                      <Link className="primary-btn btn-white" to={item.link}>Get Started</Link>
                    </div>
                    <div className="col-md text-center text-md-right">
                      <img src={item.feature.url} alt="banner slider" className="img-fluid rounded" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    )
  }
}
