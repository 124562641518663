

import React from 'react';
import { Asyncaxioscall } from '../helper/Asyncaxioscall';
import Star from './rating';
import { LazyLoadImage} from 'react-lazy-load-image-component';
const Review = ({ limit = 100 }) => {

    const [items, setResponse] = React.useState('');
    React.useEffect(() => {
        let url = limit ? 'wp-json/wp/v2/review?per_page=' + limit + '&_embed' : 'wp-json/wp/v2/review?_embed';
        Asyncaxioscall(url).then((result) => {
            setResponse(result.data);
        });
    }, [limit]);
    if (!items) return null;
    return (
        <div className="py-5 bg-gray">
            <div className="container">
                <div className="text-center">
                    <h2 className="mb-0">Success Stories</h2>
                    <p className="mb-4">See our happy clients review, feedback and recommendation.</p>
                </div>
                <div className="row">
                    {items.map((item, key) => (
                        <div className="col-lg-6 mb-4" key={'review_' + key}>
                            <div className="review-content equalHeight rounded">
                                {item.acf && <a href={item.acf.web_url} target="_blank" rel="noreferrer"><LazyLoadImage src={item.acf.company_logo.url} alt={item.acf.company_logo.alt} className="review-source d-flex" effect="blur" /></a>}

                                <div className="media">
                                    {item._embedded &&
                                        <LazyLoadImage title={item.title.rendered} src={item._embedded['wp:featuredmedia'][0].source_url} alt={item._embedded['wp:featuredmedia'][0].alt_text} className="img-fluid" effect="blur" />
                                    }
                                    <div className="media-body">
                                        <div className="rating"><Star rating={item.rating} /></div>
                                        <h4 className="mb-0 h5">{item.title.rendered}</h4>
                                        <div>{item.acf.position}</div>
                                    </div>
                                </div>
                                <div className="main" dangerouslySetInnerHTML={{ __html: item.content.rendered }}></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default Review;
