import React from 'react';
import { Asyncaxioscall } from '../helper/Asyncaxioscall';
const Contact = () => {
    const [items, setResponse] = React.useState('');
    React.useEffect(() => {
        let url = 'wp-json/acf/v2/options';
        Asyncaxioscall(url).then((result) => {
            setResponse(result.data);
        });
    }, []);
    if (!items) return null;
    return (
        <div className="bg-gray py-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="shadow p-3 rounded">
                        <div className="row">
                            <div className="col-lg-5 mb-4">
                                <h2 className="c-title">Contact 24/7</h2>
                                <div className="feature-block">
                                    <figure><i className="las la-map-marker-alt"></i></figure>
                                    <div className="content-block">
                                        <strong className="d-block">Address</strong>
                                        <p className="mb-0" dangerouslySetInnerHTML={{ __html: items.acf.address }}></p>
                                    </div>
                                </div>

                                <div className="feature-block">
                                    <figure><i className="las la-envelope-open-text"></i></figure>
                                    <div className="content-block">
                                        <strong className="d-block">Email</strong>
                                        <p className="mb-0">{items.acf.email}</p>
                                    </div>
                                </div>

                                <div className="feature-block">
                                    <figure><i className="las la-phone-volume"></i></figure>
                                    <div className="content-block">
                                        <strong className="d-block">Contact</strong>
                                        <p className="mb-0">{items.acf.contact}</p>
                                    </div>
                                </div>

                                <div className="feature-block">
                                    <figure><i className="las la-business-time"></i></figure>
                                    <div className="content-block">
                                        <strong className="d-block">Opening Days</strong>
                                        <p className="mb-0">{items.acf.opening_days}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                               <h2 className="c-title">Google Map</h2>
                               <iframe src={items.acf.google_map_link}
                                className="w-100 rounded" height="252" frameBorder="0" allowFullScreen="" title="Offshore Google Map"></iframe>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
export default Contact;