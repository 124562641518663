import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import API from '../api';
import { LazyLoadImage} from 'react-lazy-load-image-component';
export default class Process extends Component {
    state = {
        items: []
    }
    _isMounted = false;
    componentDidMount() {
        this._isMounted = true;
        API.get('wp-json/acf/v2/options/process')
            .then(res => {
                if (this._isMounted) {
                    const items = res.data.process;
                    this.setState({ items });
                }
            })

    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <div className="py-5 tab-process">
                <div className="container">
                    <div className="text-center">
                        <h2 className="mb-0">Offshore Working Mechanism</h2>
                        <p className="mb-4">Checkout our 6 standard steps to boost your online business from initial phase.</p>
                    </div>
                    <Tabs className="justify-content-center">
                        {this.state.items.map((item, key) => (
                            <Tab eventKey={item.title}
                                title={<div className="text-center justify-content-center align-item-center d-flex"><div>
                                    <div className="myIcon"><div dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                    </div><div className="mt-2 d-lg-block d-none">{item.title}</div></div></div>}
                                key={'process_' + key}>
                                <div className="row">
                                    <div className="col-lg-7 mb-4">
                                        <div className="main" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                    </div>
                                    <div className="col-lg-5 mb-4 align-self-center">
                                    <LazyLoadImage title={item.title} src={item.image.url} alt={item.image.alt} className="rounded w-100 shadow p-1" effect="blur" />
                                    </div>
                                </div>
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            </div>
        )
    }
}
