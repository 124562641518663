import API from '../api';
export function Asyncaxioscall(slug) {
  return new Promise((resolve, reject) =>{
    const processDataAsycn = async () => {  
      const response = await API.get(slug);   
      return response;  
    };  
    processDataAsycn()
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
}