import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/home';
import Page from './pages/page';
import SingleService from './pages/singleService';
import SinglePortfolio from './pages/singlePortfolio';
import NotFound from './pages/notfound';
const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home}></Route>
      <Route exact path="/:slug" component={Page}></Route>
      <Route exact path="/service/:slug" component={SingleService}></Route>
      <Route exact path="/portfolio/:slug" component={SinglePortfolio}></Route>
      <Route path="*" component={NotFound}></Route>
    </Switch>
  </BrowserRouter>
);

export default Routes;