import React, { Component } from 'react';
import API from '../api';
import SEO from '../components/seo';
export default class Welcome extends Component {
    state = {
        homeData: '',
        title: ''
    }
    _isMounted = false;
    componentDidMount() {
        this._isMounted = true;
        API.get('wp-json/wp/v2/pages/?slug=home')
            .then(res => {
                if (this._isMounted) {
                let title='';
                const homeData = res.data[0] ? res.data[0] : '';
                if(homeData && homeData.yoast_meta[4].property==="og:title"){
                    title=homeData.yoast_meta[4].content;
                }
                else if(homeData && homeData.yoast_meta[3].property==="og:title"){
                    title=homeData.yoast_meta[3].content;
                }
                this.setState({ homeData, title });
            }
            });
    }
    componentWillUnmount() {
        this._isMounted = false;
      }
    render() {
        return (
            <div className="py-5 bg-gray">
                { this.state.homeData.acf &&
                    <SEO yoast_head={this.state.homeData.yoast_head} title={this.state.title} keyword={this.state.homeData.acf.keyword}/>
                }
                <div className="container">
                    <div className="text-center">
                        <h1 className="mb-0">Offshore Soft Tech</h1>
                        <p className="mb-4">Encourage Your Venture Brand</p>
                    </div>
                    {this.state.homeData.content &&
                        <div className="main" dangerouslySetInnerHTML={{ __html: this.state.homeData.content.rendered }}></div>
                    }
                </div>
            </div>
        )
    }
}
