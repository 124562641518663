import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Alert } from 'react-bootstrap';
import { Asyncaxioscall } from '../helper/Asyncaxioscall';
import API from '../api';
const Quoteform = () => {
    const [pageData, setResponse] = React.useState('');
    const [isLoading, setIsloading] = React.useState(false);
    React.useEffect(() => {
        Asyncaxioscall('wp-json/wp/v2/service').then((result) => {
            setResponse(result.data);
        });
    }, []);

    const { register, errors, handleSubmit } = useForm();
    const [successMessage, setData] = useState(null);

    const onSubmit = (data,e) => {
        setIsloading(true);
        const datalist = JSON.stringify(data);
        API.post('wp-json/wp/v2/new-quote',datalist,{ 'Content-Type': 'application/json'})
        .then(function (response) {
            if(response.data.status){
                e.target.reset();
                setData("Thank you for your request. Our team will response you within an hour or as soon as possible");
                setIsloading(false);
                setTimeout(function(){
                    window.location.reload();
                 }, 5000);
            }
        });
    };
    return (
        <div className="bg-gray py-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9">

                        <form onSubmit={handleSubmit(onSubmit,this)}  className="shadow p-lg-5 p-3 rounded">
                            <p>Please fill up the below form with including brief explanation of your project.
                        After submission of your details, our representative team will response you with in an hour or as soon as possible.</p>
                            <div className="row">
                                <input type="hidden" name="action" value="newRequest" ref={register}/>
                                <div className="form-group col-lg-12"><label>Company Name<span className="text-danger">*</span></label>
                                    <input type="text" name="companyName" className="form-control"
                                        ref={register({ required: "This field is required." })}
                                    />
                                    {errors.companyName && <div className="text-danger d-block mt-1">{errors.companyName.message}</div>}
                                </div>
                                <div className="form-group col-lg-6"><label>Full Name<span className="text-danger">*</span></label>
                                    <input type="text" name="fullName" className="form-control"
                                        ref={register({ required: "This field is required." })}
                                    />
                                    {errors.fullName && <div className="text-danger d-block mt-1">{errors.fullName.message}</div>}
                                </div>
                                <div className="form-group col-lg-6"><label>Email Address<span className="text-danger">*</span></label>
                                    <input type="text" name="emailAddress" className="form-control"
                                        ref={register({ required: "This field is required.", pattern: /^\S+@\S+$/i })}
                                    />
                                    {errors.emailAddress && <div className="text-danger d-block mt-1">{errors.emailAddress.message}</div>}
                                    {errors.emailAddress && errors.emailAddress.type === "pattern" && <div className="text-danger d-block mt-1">Invalid Email Address</div>}
                                </div>
                                <h4 className="col-12">How can we help you?</h4>
                                {pageData && pageData.map((item, key) => (
                                    <div className="form-group col-lg-6" key={'service' + item.id}>
                                        <div className="form-check">
                                            <input className="form-check-input" name="services[]" type="checkbox" value={item.title.rendered} id={'service' + item.id} ref={register} />
                                            <label className="form-check-label" htmlFor={'service' + item.id}>{item.title.rendered}</label>
                                        </div>
                                    </div>
                                ))}
                                <div className="col-12"></div>


                                <div className="form-group col-lg-6"><label>Contact<span className="text-danger">*</span></label>
                                    <input type="text" name="contact" className="form-control"
                                        ref={register({ required: "This field is required." })}
                                    />
                                    {errors.contact && <div className="text-danger d-block mt-1">{errors.contact.message}</div>}
                                </div>

                                <div className="form-group col-lg-6"><label>Address</label>
                                    <input type="text" name="address" className="form-control"
                                        ref={register()}
                                    />
                                </div>
                                <div className="form-group col-12"><label>Project Short Description</label>
                                    <textarea name="projectDescription" className="form-control" rows="4"
                                        ref={register()}
                                    >
                                    </textarea>
                                </div>

                                <div className="col-12"><button className="primary-btn" disabled={isLoading}>{isLoading ?'Loading...':'Request Now'}</button></div>
                            </div>
                            {successMessage && <Alert variant="success" className="mt-4">{successMessage}</Alert>}
                        </form>

                    </div>
                </div>
                
            </div>
        </div>
    );
}
export default Quoteform;