import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import API from '../api';
export default class Mainmenu extends Component {
  state = {
    items: [],
  }
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    API.get('wp-json/wp/v2/main-menu')
      .then(res => {
        if (this._isMounted) {
        const items = res.data;
        this.setState({ items });
        }
      })
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <Nav className="navbar-nav d-flex w-100 align-items-center justify-content-between">
      {this.state.items.map(item => (
        <li className={'nav-item ' + item.classes[0]} key={'main_' + item.ID}>
          <Link to={item.slug} className="nav-link" >{item.title}</Link>
          </li>
      ))}
      </Nav>
    )
  }
}