import React from 'react';
const Content = (item) => {
    return (
        <div className="bg-white py-5">
            <div className="container">
                {item.content &&  <div className="main" dangerouslySetInnerHTML={{ __html: item.content.rendered }}></div>}
                {item.acf && item.acf.web_link && <a href={item.acf.web_link} target="_blank" rel="noreferrer" className="primary-btn">Visit Site</a>}
            </div>
        </div>
    );
}
export default Content;