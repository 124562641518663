import { Helmet } from "react-helmet";
const Loader = () => {
    return (
        <div className="loader">
            <Helmet key="second_helmet">
                <title>Loading...</title>
            </Helmet>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    );
}
export default Loader;